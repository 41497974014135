<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="productImg1" :alt="altText" class="imgItem1" />
        <ul class="dotList">
          <li
            :class="{
              dot1: currentDotIndex === 1,
              dot2: currentDotIndex === 2,
            }"
            @click="toggleNewImage(2)"
          ></li>
          <!-- 第一个小圆点 -->
          <li
            :class="{
              dot1: currentDotIndex === 2,
              dot2: currentDotIndex === 1,
            }"
            @click="toggleNewImage(3)"
          ></li>
          <!-- 第二个小圆点 -->
        </ul>
      </div>
    </div>
    <div :class="{ 'imgBox2': !showNewImage2, 'imgBox2-special': showNewImage2 }" min-w-1400>
      <div class="img2 max-w-1400">
        <img
          :src="showNewImage2 ? productImg2 :AnswerImg2 "
          :alt="altText"
          class="imgItem2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import productImg1 from "@/page/urieosmóvil/components/img/4_1.png";
import productImg2 from "@/page/urieosmóvil/components/img/4_3.png";
import AnswerImg2 from "@/page/urieosmóvil/components/img/4_2.png";

export default {
  data() {
    return {
      productImg1: productImg1,
      productImg2: productImg2,
      AnswerImg2: AnswerImg2,
      altText: "图片",
      showNewImage2: false,
      currentDotIndex: 1, // 当前激活的小圆点索引
    };
  },
  created() {
    this.gotoTop();
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    toggleNewImage(imageIndex) {
      if (imageIndex === 3) {
        this.showNewImage2 = !this.showNewImage2;
        this.currentDotIndex = 2; // 更新当前激活的小圆点索引
      }
      if (imageIndex === 2) {
        this.showNewImage2 = !this.showNewImage2;
        this.currentDotIndex = 1; // 更新当前激活的小圆点索引
      }
    },
  },
};
</script>


<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
}
.imgBox1 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 4050px;
  background-color: #ffffff;
  border-radius: 32px;
  margin-top: -70px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  position: relative;
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
  height: auto; /* 图片高度自动 */
  position: relative; /* 相对定位，以便在其中定位小圆点 */
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.dotList {
  position: absolute; /* 绝对定位 */
  top: 100px; /* 与图片顶部的距离 */
  left: 50%; /* 水平居中 */
  transform: translateX(-50%); /* 水平居中 */
  list-style: none; /* 去除列表样式 */
  padding: 0; /* 去除内边距 */
  display: flex;
}

.dot1 {
  width: 40px; /* 圆点的宽度 */
  height: 40px; /* 圆点的高度 */
  background-color: #fff; /* 圆点的颜色 */
  border-radius: 50%; /* 将方形变成圆形 */
  margin: 0 15px; /* 圆点之间的间距 */
}
.dot2 {
  width: 40px; /* 圆点的宽度 */
  height: 40px; /* 圆点的高度 */
  background-color: #ccc; /* 圆点的颜色 */
  border-radius: 50%; /* 将方形变成圆形 */
  margin: 0 15px; /* 圆点之间的间距 */
}

.imgBox2-special {
 display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 3000px;
  background-color: #ffffff;
  border-radius: 32px;
  margin-top: -70px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  position: relative;
}
</style>
