var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgBox min-w-1400"},[_c('div',{staticClass:"imgBox1 min-w-1400"},[_c('div',{staticClass:"img1 max-w-1400"},[_c('img',{staticClass:"imgItem1",attrs:{"src":_vm.productImg1,"alt":_vm.altText}}),_c('ul',{staticClass:"dotList"},[_c('li',{class:{
            dot1: _vm.currentDotIndex === 1,
            dot2: _vm.currentDotIndex === 2,
          },on:{"click":function($event){return _vm.toggleNewImage(2)}}}),_c('li',{class:{
            dot1: _vm.currentDotIndex === 2,
            dot2: _vm.currentDotIndex === 1,
          },on:{"click":function($event){return _vm.toggleNewImage(3)}}})])])]),_c('div',{class:{ 'imgBox2': !_vm.showNewImage2, 'imgBox2-special': _vm.showNewImage2 },attrs:{"min-w-1400":""}},[_c('div',{staticClass:"img2 max-w-1400"},[_c('img',{staticClass:"imgItem2",attrs:{"src":_vm.showNewImage2 ? _vm.productImg2 :_vm.AnswerImg2,"alt":_vm.altText}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }